import { Box, Button, Chip, Grid, Skeleton, Typography } from '@mui/material'
import { IoAdd, IoCaretDown, IoCaretUp, IoEllipseSharp } from 'react-icons/io5'
import PhinBadge from '../PhinBadge'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCompanyThunk } from '../../../store/PartnerSlice'
import ClientCreator from '../partnersPage/clientCreator'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { isUserSelecting } from '../../../utils/userInterfaceUtils'
import { FreeTrialLockoutModal } from '../../partnerPages/components/FreeTrialLockoutModal'

function CompaniesTable () {
  const history = useHistory()
  const dispatch = useDispatch()

  const { partner, companies, loaders } = useSelector((state) => state.partner)
  const {
    isLoadingPartner
  } = loaders

  const [tablePageSize, setTablePageSize] = useState(10)
  const [companyTableRows, setCompanyTableRows] = useState([])
  const [companyCreatorModal, setCompanyCreatorModal] = useState(false)
  const [isFreeTrialLockoutModalOpen, setIsFreeTrialLockoutModalOpen] = useState(false)

  useEffect(() => {
    if (partner && companies) {
      const newCompanyValues = []

      companies.forEach((company) => {
        const companyRow = { ...company }

        companyRow.isNew = false

        if (companyRow.lastMonthEnrolledUsers === undefined) {
          companyRow.isNew = true
        }

        // Default these values to 0 if they do not exist
        if (companyRow.totalEnrolledUsers === undefined) {
          companyRow.totalEnrolledUsers = 0
        }

        if (companyRow.changeFromLastMonth === undefined) {
          companyRow.changeFromLastMonth = 0
        }

        if (companyRow.percentChangeFromLastMonth === undefined) {
          companyRow.percentChangeFromLastMonth = 0
        }

        if (companyRow.absoluteChangeFromLastMonth === undefined) {
          companyRow.absoluteChangeFromLastMonth = 0
        }

        if (companyRow.absolutePercentChangeFromLastMonth === undefined) {
          companyRow.absolutePercentChangeFromLastMonth = 0
        }

        newCompanyValues.push(companyRow)
      })

      setCompanyTableRows(newCompanyValues)
    }
  }, [partner, companies])

  const handleClientCreate = async () => {
    if (partner.freeTrial) {
      setIsFreeTrialLockoutModalOpen(!isFreeTrialLockoutModalOpen)
    } else {
      setCompanyCreatorModal(true)
    }
  }

  const createClient = async (name) => {
    const companyId = await dispatch(createCompanyThunk({ name, partnerId: partner.id }))
    return companyId
  }

  const simpleGenerateArrowNumber = (value, absoluteValue, isPercentage) => {
    const sign = Math.sign(value)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {((sign === 1) && (<IoCaretUp style={{ color: 'green' }} />))}
        {((sign === -1) && (<IoCaretDown style={{ color: 'red' }} />))}
        {isPercentage
          ? Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 0 }).format(absoluteValue)
          : absoluteValue || 0}

      </Box>
    )
  }

  const companyTableColumns = [
    {
      headerName: 'Name (Click to view)',
      field: 'name',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }} id={`partner-dashboard-view-company-button-${params.row.name}`}>

            <Grid container direction='row' alignItems='center' spacing={1}>
              <Grid item><Typography sx={{ color: 'var(--phin-blue)', fontWeight: 'bold' }}>{params.row.name.length > 20 ? params.row.name.substring(0, 20) + '...' : params.row.name}</Typography></Grid>
              {params.row.isInternal && (<Grid item><Chip size='small' label='NFR' sx={{ backgroundColor: 'var(--purple-75)', color: 'white' }} /></Grid>)}
              {params.row.isNew && (<span style={{ fontSize: '0.5em' }}><PhinBadge displayString='New' icon={<IoEllipseSharp />} color='var(--phin-blue)' /></span>)}
            </Grid>
          </Box>
        )
      }
    },
    {
      headerName: 'Total Active Users',
      field: 'totalDevices',
      flex: 0.4,
      renderCell: (params) => params.row.totalDevices
    },
    {
      headerName: 'Total Enrolled in Campaigns',
      field: 'totalEnrolledUsers',
      flex: 0.7,
      renderCell: (params) => params.row.totalEnrolledUsers
    },
    {
      headerName: 'Change in Campaign Enrollment',
      field: 'changeFromLastMonth',
      flex: 0.7,
      valueGetter: (params) => { return +params.row.changeFromLastMonth },
      renderCell: (params) => simpleGenerateArrowNumber(params.row.changeFromLastMonth, params.row.absoluteChangeFromLastMonth)
    },
    {
      headerName: 'Percent Change in Campaign Enrollment',
      field: 'percentChangeFromLastMonth',
      flex: 0.7,
      valueGetter: (params) => { return +params.row.percentChangeFromLastMonth },
      renderCell: (params) => simpleGenerateArrowNumber(params.row.percentChangeFromLastMonth, params.row.absolutePercentChangeFromLastMonth, true)
    }
  ]

  return (
    <Grid container className='margin-bottom:2'>
      {/* Create Client Modal */}
      <ClientCreator
        isOpen={companyCreatorModal}
        createClient={createClient}
        closeModal={() => { setCompanyCreatorModal(false) }}
      />

      {(isLoadingPartner || !partner) && (
        <Skeleton sx={{ bgcolor: '#c4c4c4' }} variant='rectangle' width={1110} height={400} />
      )}

      {(!isLoadingPartner && partner) && (
        <>
          <Grid container sx={{ mb: 1 }}>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <h4 className='phin-h4' style={{ textAlign: 'left' }}>Companies</h4>
            </Grid>
            <FreeTrialLockoutModal
              open={isFreeTrialLockoutModalOpen}
              feature='adding additional companies'
              featureIntroTitle='onboard additional companies'
              description='Ready to onboard additional users? This feature allows you to create as many new clients as you need! Get automated phishing and training content out to all your clients.'
              KBLink='https://www.phinsec.io/knowledge/how-to-create-a-new-client'
              closeModal={() => setIsFreeTrialLockoutModalOpen(false)}
            />
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                id='add-company-button'
                aria-label='Add a Company Button'
                variant='contained'
                disabled={!partner || isLoadingPartner}
                startIcon={<IoAdd />}
                sx={partner && partner.freeTrial ? { opacity: '0.5' } : {}}
                color={partner && partner.freeTrial ? 'phinGray' : 'primary'}
                onClick={() => {
                  handleClientCreate()
                }}
              >
                ADD A COMPANY
              </Button>
            </Grid>
          </Grid>
          <div id='company-table' style={{ width: '100%' }}>
            <DataGrid
              onRowClick={(params) => {
                if (!isUserSelecting(window)) history.push(`/companies/${params.row.id}`)
              }}
              sx={{
                borderRadius: '8px',
                border: '2px solid var(--phin-light-gray)',
                '.MuiDataGrid-cell:focus': {
                  outline: 'none'
                },
                // pointer cursor on ALL rows
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer'
                }
              }}
              className='DataGrid'
              initialState={{
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }]
                }
              }}
              autoHeight
              rows={companyTableRows}
              columns={companyTableColumns}
              pageSize={tablePageSize}
              onPageSizeChange={(newSize) => setTablePageSize(newSize)}
              rowsPerPageOptions={[10, 25, 50]}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: {
                    debounceMs: 500,
                    id: 'company-management-table-search',
                    'aria-label': 'Search'
                  },
                  printOptions: { disableToolbarButton: true },
                  csvOptions: {
                    fileName: `${partner.name}_Companies_Breakdown.csv`,
                    delimiter: ','
                  }
                }
              }}
              disableSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
            />
          </div>
        </>
      )}
    </Grid>
  )
}

export default CompaniesTable
